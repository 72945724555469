/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Width and height of scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track (background) */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle (scrollbar itself) */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.no-spinner-input {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;
  cursor: auto;
}

.no-spinner-input::-webkit-inner-spin-button,
.no-spinner-input::-webkit-outer-spin-button {
  display: none;
}

.show-spinner-input {
  -moz-appearance: auto;
  -webkit-appearance: auto;
  appearance: auto;
  cursor: text;
  opacity: 1;
}

.show-spinner-input::-webkit-inner-spin-button,
.show-spinner-input::-webkit-outer-spin-button {
  display: inline-block;
  margin: 0;
  opacity: 1;
}

h1 {
  @apply text-4xl font-bold leading-tight tracking-tight md:text-6xl lg:text-7xl xl:text-8xl;
}

h2 {
  @apply text-3xl font-bold leading-tight tracking-tight md:text-5xl lg:text-6xl xl:text-7xl;
}

h3 {
  @apply text-2xl font-semibold leading-tight tracking-tight md:text-4xl lg:text-5xl xl:text-6xl;
}

h4 {
  @apply text-xl font-semibold leading-tight tracking-tight md:text-3xl lg:text-4xl xl:text-5xl;
}

h5 {
  @apply text-lg font-medium leading-snug tracking-normal md:text-2xl lg:text-3xl xl:text-4xl;
}

h6 {
  @apply text-base font-medium leading-snug tracking-normal md:text-xl lg:text-2xl xl:text-3xl;
}

input,
select {
  @apply w-full rounded-md border p-3 focus:outline-none focus:ring focus:ring-blue-500;
}

.gradient1 {
  background: linear-gradient(
    69.76deg,
    #a3c5f9 -13.77%,
    #567af1 15.1%,
    #222dda 45.6%,
    #cbbaf1 68.87%,
    #f8f5fc 106.81%
  );
}

.gradient {
  background: #8c6bd3;
  /* background: #222dda 45.6%, */
}

.button-color {
  background-color: #1079d4;
}

.text-blue-color {
  color: #1079d4;
}

.align-left {
  align-self: flex-start;
}

.align-center {
  align-self: center;
}

.text-light-black {
  color: #606060;
}

.checkbox {
  width: 20px;
  height: 20px;
  top: 722px;
  left: 788px;
  gap: 0px;
  border-radius: 3.41px 0px 0px 0px;
  border: 0.85px 0px 0px 0px;
  opacity: 1px;
  background: var(--Base-Base-White, #fafafa);
}

.heading {
  color: #1d2f6b;
  font-size: 33px;
}

.side-heading {
  font-size: 33px;
}

.text-blue {
  color: #1e8eee;
}

.text-dark-blue {
  color: #1079d4;
}

.stepper-color {
  background-color: #6575ea;
}

.light-blue-bg {
  background-color: #daecfc;
}

.sidebar-close {
  width: 3.5rem;
}

.teal-button {
  background-color: #2a9d8a;
}

.stepper-button {
  background-color: #2a9d8a; /* Greenish color */
  border: none;
  color: white;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  font-family: Arial, sans-serif;
  margin: 20px;
}

.submit-button {
  display: none;
}
